import React, { useContext } from "react"
import moment from "moment"
import Query from "../../Query"
import StyledImage from "../../../components/StyledImage"
import ReadMorePara from "../../../components/ReadMorePara"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  getConfigValue,
  artistVideoDetailsPageURL,
  DATA_TYPE_ARTIST_VIDEO,
} from "../../../constants/props"

import { artistVideoDetailsShareURL } from "../../../constants/share"
import { QUERY_FEATURED_VIDEOS_BY_ARTIST_ID } from "../../../queries/page/video"
import * as St from "../../Styled"
import Carousel from "../../SliderContainer/carousel"
import LikeAndShare from "../../LikeAndShare"

import { AppContext } from "../../../contexts/AppContext"

const FeaturedVideo = ({ data, artist, updatePlayer, ctxPlayer, device }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { id, title, uri, subtitles, image, about, viewed, premium } = data
  const videoImage = image.url
    ? image.url.startsWith("http")
      ? image.url
      : basePrefix + image.url
    : ""

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_VIDEO &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.VideoFeaturedWrapper>
      <St.VideoFeaturedImageWrapper>
        <StyledImage
          borderRadius="0px"
          image={videoImage}
          width={"100%"}
          height={"100%"}
          playing={isPlaying}
          premium={premium}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id,
                title,
                uri,
                subtitles,
                preview:
                  image && image.url
                    ? image.url.startsWith("http")
                      ? image.url
                      : basePrefix + image.url
                    : "",
                playlist: [],
                artist,
                videoMode: VIDEO_MODE_MAXIMIZED,
                about,
                viewed: viewed != null ? viewed : 0,
                dataType: DATA_TYPE_ARTIST_VIDEO,
                playing: true,
              })
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.VideoFeaturedImageWrapper>
      <St.VideoFeaturedContainer
        videoPlaying={ctxPlayer.videoMode === VIDEO_MODE_MAXIMIZED}
      >
        <St.VideoFeaturedBottomTextSection>
          <St.VideoFeaturedColumnText>
            <St.VideoFeaturedTitle> {data.title} </St.VideoFeaturedTitle>
            <St.VideoInfoLabel>
              {subtitles && subtitles.length ? subtitles[0] + subtitles[1] : ""}
            </St.VideoInfoLabel>
            <ReadMorePara
              string={about}
              offset={getConfigValue(device, "box.videos.featured.textOffset")}
            ></ReadMorePara>
          </St.VideoFeaturedColumnText>
          <St.VideoFeaturedColumnText
            style={{ alignItems: "flex-end", flexShrink: 0 }}
          >
            <St.VideoViews>
              <span>{viewed != null ? viewed : "0"}</span>{" "}
              <span>{viewed === 1 ? "View" : "Views"}</span>
            </St.VideoViews>
            <St.FeaturedSocialIconSection>
              <LikeAndShare
                shareURL={artistVideoDetailsShareURL(
                  id,
                  convertToSlug(title),
                  artist.id,
                  artist.slug,
                  videoImage
                )}
                id={id}
                shareID={"featuredArtistVideo_" + id}
                likeType={DATA_TYPE_ARTIST_VIDEO}
              />
            </St.FeaturedSocialIconSection>
          </St.VideoFeaturedColumnText>
        </St.VideoFeaturedBottomTextSection>
      </St.VideoFeaturedContainer>
    </St.VideoFeaturedWrapper>
  )
}

const FeaturedBox = ({ artist, videos }) => {
  const { updatePlayer, device, ctxPlayer } = useContext(AppContext)
  videos =
    videos && videos.length
      ? videos.map((video, _) => {
          const v = {
            ...video,
            subtitles: [
              video.show ? video.show.title : "",
              `${artist.title} • ${moment(video.updatedAt)
                .startOf("seconds")
                .fromNow()}`,
            ],
          }
          return v
        })
      : []

  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return ctxPlayer.videoMode !== VIDEO_MODE_MAXIMIZED &&
    videos &&
    videos.length > 0 ? (
    <St.VideoFeaturedBox videoPlaying={false}>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED ARTIST VIDEO : {artist.title}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {videos &&
            videos.map((video, idx) => (
              <FeaturedVideo
                data={{
                  ...video,
                }}
                artist={{
                  id: artist.id,
                  slug: convertToSlug(artist.title),
                }}
                {...{ updatePlayer, device, ctxPlayer }}
              />
            ))}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.VideoFeaturedBox>
  ) : (
    <St.VideoFeaturedBox videoPlaying={true}>
      <St.VideoFeaturedContainer
        videoPlaying={ctxPlayer.videoMode === VIDEO_MODE_MAXIMIZED}
      >
        <St.VideoFeaturedBottomTextSection>
          <St.VideoFeaturedColumnText>
            <St.VideoTitle> {ctxPlayer.title} </St.VideoTitle>
            <St.VideoInfoLabel>
              {ctxPlayer.subtitles && ctxPlayer.subtitles.length
                ? ctxPlayer.subtitles[0] + ctxPlayer.subtitles[1]
                : ""}
            </St.VideoInfoLabel>
            <ReadMorePara string={ctxPlayer.about} offset={50}></ReadMorePara>
          </St.VideoFeaturedColumnText>
          <St.VideoFeaturedColumnText
            style={{ alignItems: "flex-end", flexShrink: 0 }}
          >
            <St.VideoViews>
              <span>{ctxPlayer.viewed != null ? ctxPlayer.viewed : "0"}</span>{" "}
              <span>{ctxPlayer.viewed === 1 ? "View" : "Views"}</span>
            </St.VideoViews>
            <St.VideoIconsSection>
              <LikeAndShare
                shareURL={artistVideoDetailsPageURL(
                  ctxPlayer.id,
                  convertToSlug(ctxPlayer.title),
                  artist.id,
                  convertToSlug(artist.title)
                )}
                id={ctxPlayer.id}
                shareID={"featuredArtistVideo_" + ctxPlayer.id}
                likeType={DATA_TYPE_ARTIST_VIDEO}
              />
            </St.VideoIconsSection>
          </St.VideoFeaturedColumnText>
        </St.VideoFeaturedBottomTextSection>
      </St.VideoFeaturedContainer>
    </St.VideoFeaturedBox>
  )
}

const FeaturedVideos = ({ artist }) => {
  return (
    <Query
      query={QUERY_FEATURED_VIDEOS_BY_ARTIST_ID}
      variables={{
        artistID: artist.id,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { videos } }) => {
        return <FeaturedBox artist={artist} videos={videos}></FeaturedBox>
      }}
    </Query>
  )
}

export default FeaturedVideos
