import React, { useContext } from "react"
import FeaturedVideos from "./FeaturedVideos"
import AllVideos from "./AllVideos"
import Query from "../Query"
import { QUERY_ARTIST_BY_ID_NAME_ONLY } from "../../queries/page/artist"
import * as St from "../Styled"
import { DEVICE_LANDSCAPE } from "../../constants/breakpoints"
import { VIDEO_MODE_MAXIMIZED } from "../../constants/props"
import { AppContext } from "../../contexts/AppContext"

const ContentArtistVideos = props => {
  const { device, ctxPlayer } = useContext(AppContext)
  return (
    <Query
      query={QUERY_ARTIST_BY_ID_NAME_ONLY}
      variables={{
        id: props.artistID,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { artist } }) => {
        return device !== DEVICE_LANDSCAPE ||
          ctxPlayer.videoMode !== VIDEO_MODE_MAXIMIZED ? (
          <St.ContentParent>
            <St.AdWrapper>
              <St.LeftAdSection />
              <St.LeftAd2Section />
            </St.AdWrapper>
            <St.ContentWrapper>
              <FeaturedVideos artist={artist} />
              <St.CenterContentSection>
                <AllVideos artist={artist} />
              </St.CenterContentSection>
              <St.BottomAdSection />
            </St.ContentWrapper>
            <St.AdWrapper>
              <St.RightAdSection />
              <St.RightAd2Section />
            </St.AdWrapper>
          </St.ContentParent>
        ) : null
      }}
    </Query>
  )
}

export default ContentArtistVideos
