import React from "react"
import ContentArtistVideos from "../components/ContentArtistVideos"
import queryString from "query-string"

const Videos = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}
  return (
    <React.Fragment>
      <ContentArtistVideos
        artistID={queryParams.__aid}
        artistName={queryParams.a}
      />
    </React.Fragment>
  )
}

export default Videos
